import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  //Typography,
  //Box,
} from "@material-ui/core";
import { variables } from "../../../cssInJs";
//import { Programs } from "./programs";
import {
  //useParams,
  Link,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "1px",
  },
  tab: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "72px",
    },
  },
  appBar: {
    backgroundColor: variables.colorLightGray,
    color: variables.fontColor,
  },
}));

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

const getValue = (pathname) => {
  let result = "classes";
  if (
    pathname.includes("/program/new") ||
    pathname.includes("/programs") ||
    pathname.includes("/program/")
  ) {
    result = "programs";
  }
  return result;
}

function createTab(id, label, link = null) {
  return { id, label, link };
}

const tabItems = [
  createTab("classes", "Classes", "classes"),
  createTab("classHistory", "Class History", "class-history"),
  createTab("classList", "Class List", "class-list"),
  createTab("privateLessons", "Private Lessons", "private-classes"),
  createTab("birthdayParties", "Birthday Parties", "birthday-party"),
  createTab("events", "Events", "studio-setting", "events"),
  createTab("referralFriends", "Referral Friends", "referral-friends"),
];

export function StudioMenuStudio({ studioId, pathname }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(() => {
    return getValue(pathname);
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          {tabItems.map((row, index) => {
            const link = row.link ? row.link : row.id;
            return (
              <Tab
                key={row.id}
                value={row.id}
                label={row.label}
                wrapped
                component={Link}
                to={`/dashboard/studio/${studioId}/${link}`}
                className={classes.tab}
                {...a11yProps(row.id)}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </div>
  );
}
