import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import DashboardLayoutRoute from "layout/dashboardLayout";
import { Dashboard } from "site/members/dashboard/dashbaord";
import { Studios } from "site/members/studios/studios";
//import EnhancedTable from "site/members/studios/studios_test";
import { StudioSection } from "site/members/studio/studio";
import { StudioEdit } from "site/members/studios/studioEdit";
import { SetupAwards } from "site/members/setup/setupAwards";
import { SetupAward } from "site/members/setup/setupAward";
import { SetupPrograms } from "site/members/setup/setupPrograms";

// import { Login } from 'auth';
// import { ChangePassword } from 'site/members/profile/changePassword';
// import { ForgotPassword } from 'auth/controls/forgotPassword/forgotPassword';
// import { ResetPassword } from 'auth/controls/resetPassword/resetPassword';
// import { VerifyEmail } from 'auth/controls/verifyEmail/verifyEmail';
// import { CreateAccountLoading } from 'auth/controls/createAccount/createAccount';
//import DashboardNoAuthLayoutRoute from 'layout/dashboardNoAuthLayout';
//import { MenuLoader } from './menuLoader';

const MemberLayout = ({ children }) => (
  <Switch>
    {/* <DashboardLayoutRoute
      //exact
      path="/dashboard/studio/new"
      component={Studio}
    />
     */}

    {/* <DashboardLayoutRoute
      exact
      path="/dashboard/studio/:studioId/dashboard"
      component={Studio}
    /> */}

    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-award/new"
      component={SetupAward}
    />

    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-award/:setupAwardId"
      component={SetupAward}
    />

    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-awards"
      component={SetupAwards}
    />

    <DashboardLayoutRoute
      exact
      path="/dashboard/property-metrics/setup-programs"
      component={SetupPrograms}
    />

    <DashboardLayoutRoute
      exact
      path="/dashboard/studio-edit/new"
      component={StudioEdit}
    />

    <DashboardLayoutRoute
      exact
      path="/dashboard/studio-edit/:studioId"
      component={StudioEdit}
    />

    <DashboardLayoutRoute
      path="/dashboard/studio/:studioId"
      component={StudioSection}
    />

    {/* <DashboardLayoutRoute
      exact
      path="/dashboard/studio/new"
      component={Studio}
    /> */}

    <DashboardLayoutRoute exact path="/dashboard/studios" component={Studios} />
    <DashboardLayoutRoute exact path="/dashboard" component={Dashboard} />

    <Route path="/">
      <Redirect to="/dashboard" status="301" />
    </Route>
  </Switch>
);
export default MemberLayout;
