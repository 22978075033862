import React from "react";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  //DialogContentText,
  //Box,
  DialogActions,
  Grid,
  //Backdrop,
} from "@material-ui/core";
import { variables } from "../../../cssInJs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { Loading, ErrorMessage, SnackbarMessage } from "components";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import { yupResolver } from "@hookform/resolvers/yup";
import env from "../../../env";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import utils from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleFilter,
    studioId,
    deleteBatches,
    activeBatches,
    inActiveBatches,
  } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Private Lessons
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create Batch" arrow>
          <Button
            component={Link}
            to={`/dashboard/studio/${studioId}/private-class/new`}
          >
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Active" arrow>
          <Button onClick={activeBatches}>
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="In Active" arrow>
          <Button onClick={inActiveBatches}>
            <RemoveCircleIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Delete Batches" arrow>
          <Button onClick={deleteBatches}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

const schema = yup.object().shape({
  search: yup.string(),
  termId: yup.string(),
  programId: yup.string(),
});

export function PrivateLessons() {
  const { studioId } = useParams(); //termId
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("BatchName");
  const [data, setData] = React.useState([]);
  const [programs, setPrograms] = React.useState();
  const [terms, setTerms] = React.useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  //const [cloneBatchItems, setCloneBatchItems] = React.useState([]);
  const [cloneBatchTerms, setCloneBatchTerms] = React.useState([]);
  const [openErrorScb, setOpenErrorScb] = React.useState(false);

  const [autoValue, setAutoValue] = React.useState({
    program: null,
    term: null,
  });

  const previousController = React.useRef();
  const timerController = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [trigger, setTrigger] = React.useState(0);
  const {
    getValues,
    register,
    setValue,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { search: "" },
  });

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  async function activePrivateLesson() {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/activeBatches", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedBatchIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
      } else {
        //history.push(`/dashboard/studios`);
        window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  async function deletePrivateLesson() {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/deletebatches", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedBatchIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
      } else {
        //history.push(`/dashboard/studios`);
        window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  async function inActivePrivateLesson() {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/inactivebatches", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedBatchIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
      } else {
        //history.push(`/dashboard/studios`);
        window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/classes/getbatches", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            page: page,
            rowsPerPage: rowsPerPage,
            order: order,
            orderBy: orderBy,
            ...getValues(),
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.batches) {
          setData(data.results.batches);
        }

        if (data.results && data.results.programs) {
          setPrograms(data.results.programs);
        }

        if (data.results && data.results.terms) {
          setTerms(data.results.terms);
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }

      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [trigger]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleFilter={handleFilter}
          studioId={studioId}
          delete={deletePrivateLesson}
          active={activePrivateLesson}
          inActive={inActivePrivateLesson}
        />
      </Paper>
    </div>
  );
}
