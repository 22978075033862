import React from "react";
import {
  AppBar,
  Tab,
  Tabs,
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  //Grid,
  //Backdrop,
} from "@material-ui/core";
import { variables } from "../../../cssInJs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import { Loading, ErrorMessage } from "components";
import CircularProgress from "@material-ui/core/CircularProgress";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
//import DeleteIcon from "@material-ui/icons/Delete";
//import FileCopyIcon from "@material-ui/icons/FileCopy";
//import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import { yupResolver } from "@hookform/resolvers/yup";
import env from "../../../env";
import Autocomplete from "@material-ui/lab/Autocomplete";
import utils from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
}));

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

export function Classlist() {
  const { studioId } = useParams(); //termId
  const classes = useStyles();
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("ProgramName");
  const [getCLResult, setGetCLResult] = React.useState();
  const [tab, setTab] = React.useState();
  //const [openErrorScb, setOpenErrorScb] = React.useState(false);

  const [autoValue, setAutoValue] = React.useState({
    term: null,
  });

  const previousController = React.useRef();
  const timerController = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [trigger, setTrigger] = React.useState(0);

  const handleChange = (event, newValue) => {
    // setCurrentTab(newValue);
    // setOrder("asc");
    // setOrderBy("Position");
    // setValue("search", "");
    // setBackdropOpen(true);
    // setTrigger(trigger + 1);
    setTab(newValue);
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/classes/getclasslist", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            //page: page,
            //rowsPerPage: rowsPerPage,
            //order: order,
            //orderBy: orderBy,
            //...getValues(),
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getCLResult) {
          const _programs = data.results.getCLResult.programs;
          setTab(_programs[0].programId);
          setGetCLResult({
            ...data.results.getCLResult,
          });
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }

      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [trigger]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            {getCLResult.programs.map((row, index) => {
              return (
                <Tab
                  key={row.programId}
                  value={row.programId}
                  label={row.programName}
                  wrapped
                  {...a11yProps(row.programId)}
                />
              );
            })}
          </Tabs>
        </AppBar>
      </Paper>
    </div>
  );
}
