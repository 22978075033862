import React from "react";
import env from "../../../env";
import { Link, useLocation, useParams } from "react-router-dom";
import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
import { variables } from "../../../cssInJs";
import {
  makeStyles,
  AppBar,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  //TextField,
  //FormHelperText,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const useStylesTabView = makeStyles((theme) => ({
  table: {
    //minWidth: 750,
  },
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

// const headCellsAttend = [
//   {
//     id: "Iamge",
//     numeric: false,
//     disablePadding: false,
//     label: "Iamge",
//     hasSort: false,
//   },
//   {
//     id: "FirstName",
//     numeric: true,
//     disablePadding: false,
//     label: "First Name",
//     hasSort: false,
//   },
//   {
//     id: "LastName",
//     numeric: false,
//     disablePadding: false,
//     label: "Last Name",
//     hasSort: false,
//   },
//   {
//     id: "Info",
//     numeric: false,
//     disablePadding: false,
//     label: "Info",
//     hasSort: false,
//   },
//   {
//     id: "BeltLevel",
//     numeric: false,
//     disablePadding: false,
//     label: "Belt Level",
//     hasSort: false,
//   },
//   {
//     id: "Age",
//     numeric: false,
//     disablePadding: false,
//     label: "Age",
//     hasSort: false,
//   },
//   {
//     id: "Attendance",
//     numeric: false,
//     disablePadding: false,
//     label: "Attendance",
//     hasSort: false,
//   },
//   {
//     id: "AbsentEmail",
//     numeric: false,
//     disablePadding: false,
//     label: "Absent Email",
//     hasSort: false,
//   },
//   {
//     id: "Comment",
//     numeric: false,
//     disablePadding: false,
//     label: "Comment",
//     hasSort: false,
//   },
//   {
//     id: "Camera",
//     numeric: false,
//     disablePadding: false,
//     label: "Camera",
//     hasSort: false,
//   },
// ];

// const headCellsRewards = [
//   {
//     id: "Iamge",
//     numeric: false,
//     disablePadding: false,
//     label: "Iamge",
//     hasSort: false,
//   },
//   {
//     id: "FirstName",
//     numeric: true,
//     disablePadding: false,
//     label: "First Name",
//     hasSort: false,
//   },
//   {
//     id: "LastName",
//     numeric: false,
//     disablePadding: false,
//     label: "Last Name",
//     hasSort: false,
//   },
//   {
//     id: "Info",
//     numeric: false,
//     disablePadding: false,
//     label: "Info",
//     hasSort: false,
//   },
//   {
//     id: "RedStart",
//     numeric: false,
//     disablePadding: false,
//     label: "Red Start",
//     hasSort: false,
//   },
//   {
//     id: "HouseTeamAward",
//     numeric: false,
//     disablePadding: false,
//     label: "House Team Award",
//     hasSort: false,
//   },
//   {
//     id: "BlueStart",
//     numeric: false,
//     disablePadding: false,
//     label: "Blue Start",
//     hasSort: false,
//   },
//   {
//     id: "OrangeStart",
//     numeric: false,
//     disablePadding: false,
//     label: "Orange Start",
//     hasSort: false,
//   },
//   {
//     id: "GoldStart",
//     numeric: false,
//     disablePadding: false,
//     label: "Gold Start",
//     hasSort: false,
//   },
//   {
//     id: "GreenStart",
//     numeric: false,
//     disablePadding: false,
//     label: "Green Start",
//     hasSort: false,
//   },
//   {
//     id: "Home Work",
//     numeric: false,
//     disablePadding: false,
//     label: "Home Work",
//     hasSort: false,
//   },
//   {
//     id: "SpecialBadges",
//     numeric: false,
//     disablePadding: false,
//     label: "Special Badges",
//     hasSort: false,
//   },
// ];

// const headCellsDiscipline = [
//   {
//     id: "Iamge",
//     numeric: false,
//     disablePadding: false,
//     label: "Iamge",
//     hasSort: false,
//   },
//   {
//     id: "FirstName",
//     numeric: true,
//     disablePadding: false,
//     label: "First Name",
//     hasSort: false,
//   },
//   {
//     id: "LastName",
//     numeric: false,
//     disablePadding: false,
//     label: "Last Name",
//     hasSort: false,
//   },
//   {
//     id: "Info",
//     numeric: false,
//     disablePadding: false,
//     label: "Info",
//     hasSort: false,
//   },
//   {
//     id: "Reminder",
//     numeric: false,
//     disablePadding: false,
//     label: "Reminder",
//     hasSort: false,
//   },
//   {
//     id: "Warning",
//     numeric: false,
//     disablePadding: false,
//     label: "Warning",
//     hasSort: false,
//   },
//   {
//     id: "Timeout",
//     numeric: false,
//     disablePadding: false,
//     label: "Timeout",
//     hasSort: false,
//   },
// ];

const headCells = [
  [
    {
      id: "Iamge",
      numeric: false,
      disablePadding: false,
      label: "Iamge",
      hasSort: false,
    },
    {
      id: "FirstName",
      numeric: true,
      disablePadding: false,
      label: "First Name",
      hasSort: false,
    },
    {
      id: "LastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      hasSort: false,
    },
    {
      id: "Info",
      numeric: false,
      disablePadding: false,
      label: "Info",
      hasSort: false,
    },
    {
      id: "BeltLevel",
      numeric: false,
      disablePadding: false,
      label: "Belt Level",
      hasSort: false,
    },
    {
      id: "Age",
      numeric: false,
      disablePadding: false,
      label: "Age",
      hasSort: false,
    },
    {
      id: "Attendance",
      numeric: false,
      disablePadding: false,
      label: "Attendance",
      hasSort: false,
    },
    {
      id: "AbsentEmail",
      numeric: false,
      disablePadding: false,
      label: "Absent Email",
      hasSort: false,
    },
    {
      id: "Comment",
      numeric: false,
      disablePadding: false,
      label: "Comment",
      hasSort: false,
    },
    {
      id: "Camera",
      numeric: false,
      disablePadding: false,
      label: "Camera",
      hasSort: false,
    },
  ],
  [
    {
      id: "Iamge",
      numeric: false,
      disablePadding: false,
      label: "Iamge",
      hasSort: false,
    },
    {
      id: "FirstName",
      numeric: true,
      disablePadding: false,
      label: "First Name",
      hasSort: false,
    },
    {
      id: "LastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      hasSort: false,
    },
    {
      id: "Info",
      numeric: false,
      disablePadding: false,
      label: "Info",
      hasSort: false,
    },
    {
      id: "RedStart",
      numeric: false,
      disablePadding: false,
      label: "Red Start",
      hasSort: false,
    },
    {
      id: "HouseTeamAward",
      numeric: false,
      disablePadding: false,
      label: "House Team Award",
      hasSort: false,
    },
    {
      id: "BlueStart",
      numeric: false,
      disablePadding: false,
      label: "Blue Start",
      hasSort: false,
    },
    {
      id: "OrangeStart",
      numeric: false,
      disablePadding: false,
      label: "Orange Start",
      hasSort: false,
    },
    {
      id: "GoldStart",
      numeric: false,
      disablePadding: false,
      label: "Gold Start",
      hasSort: false,
    },
    {
      id: "GreenStart",
      numeric: false,
      disablePadding: false,
      label: "Green Start",
      hasSort: false,
    },
    {
      id: "Home Work",
      numeric: false,
      disablePadding: false,
      label: "Home Work",
      hasSort: false,
    },
    {
      id: "SpecialBadges",
      numeric: false,
      disablePadding: false,
      label: "Special Badges",
      hasSort: false,
    },
  ],
  [
    {
      id: "Iamge",
      numeric: false,
      disablePadding: false,
      label: "Iamge",
      hasSort: false,
    },
    {
      id: "FirstName",
      numeric: true,
      disablePadding: false,
      label: "First Name",
      hasSort: false,
    },
    {
      id: "LastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      hasSort: false,
    },
    {
      id: "Info",
      numeric: false,
      disablePadding: false,
      label: "Info",
      hasSort: false,
    },
    {
      id: "Reminder",
      numeric: false,
      disablePadding: false,
      label: "Reminder",
      hasSort: false,
    },
    {
      id: "Warning",
      numeric: false,
      disablePadding: false,
      label: "Warning",
      hasSort: false,
    },
    {
      id: "Timeout",
      numeric: false,
      disablePadding: false,
      label: "Timeout",
      hasSort: false,
    },
  ],
  [
    {
      id: "Iamge",
      numeric: false,
      disablePadding: false,
      label: "Iamge",
      hasSort: false,
    },
    {
      id: "FirstName",
      numeric: true,
      disablePadding: false,
      label: "First Name",
      hasSort: false,
    },
    {
      id: "LastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      hasSort: false,
    },
    {
      id: "Info",
      numeric: false,
      disablePadding: false,
      label: "Info",
      hasSort: false,
    },
    {
      id: "BeltLevel",
      numeric: false,
      disablePadding: false,
      label: "Belt Level",
      hasSort: false,
    },
    {
      id: "Age",
      numeric: false,
      disablePadding: false,
      label: "Age",
      hasSort: false,
    },
    {
      id: "Attendance",
      numeric: false,
      disablePadding: false,
      label: "Attendance",
      hasSort: false,
    },
    {
      id: "BBA",
      numeric: false,
      disablePadding: false,
      label: "BBA",
      hasSort: false,
    },
    {
      id: "Upgrade",
      numeric: false,
      disablePadding: false,
      label: "Upgrade",
      hasSort: false,
    },
  ],
];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const TabViewItem = (props) => {
  const classes = useStylesTabView();
  const { value, headCells, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            {headCells.map((headCell) => {
              if (headCell.hasSort) {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

const createTab = (value, label) => {
  return { value, label };
};

const tabs = [
  createTab("attendance", "Attendance"),
  createTab("rewards", "Rewards"),
  createTab("discipline", "Discipline"),
  createTab("graduation", "Graduation"),
];

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

export function ClassDetails() {
  const classes = useStyles();
  const { studioId, classId } = useParams(); //termId
  const previousController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [getCDResultModel, setGetCDResultModel] = React.useState();
  const [tab, setTab] = React.useState(tabs[0].value);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/classes/getclassdetails", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            classId: classId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getCDResultModel) {
          //let editMode = false;
          //let newBatch = true;

          //const _setupAward = data.results.getSetupAwardResult.setupAward;
          //const _awardTypeList = data.results.getSetupAwardResult.awardTypeList;

          //   if (_setupAward) {
          //     editMode = true;
          //     newBatch = false;
          //   }
          setGetCDResultModel({
            ...data.results.getSetupAwardResult,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            {tabs.map((row, index) => {
              return (
                <Tab
                  wrapped
                  key={row.value}
                  value={row.value}
                  label={row.label}
                  {...a11yProps(row.value)}
                />
              );
            })}
          </Tabs>
        </AppBar>
        {tabs.map((row, index) => {
          return (
            <TabPanel key={row.value} value={tab} index={row.value}>
              <TabViewItem value={row.value} headCells={headCells[index]} />
            </TabPanel>
          );
        })}
      </Paper>
    </div>
  );
}
