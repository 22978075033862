import React, { useState } from "react";
import { Route, Link } from "react-router-dom";
import {
  Loading,
  //useRouter
} from "components";
import { useAuth } from "auth";
//import { MenuLoader } from "./menuLoader";
import { useStore } from "../store";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
//import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
//import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
//import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import InboxIcon from "@material-ui/icons/MoveToInbox";
//import MailIcon from "@material-ui/icons/Mail";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { ReactComponent as YourSvg } from "../site/members/svg/new_captain_ki_ahp.svg";
import CloseIcon from "@material-ui/icons/Close";
import { variables } from "../cssInJs";
import SendIcon from "@material-ui/icons/Send";
import DraftsIcon from "@material-ui/icons/Drafts";
import {
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  CssBaseline,
  Collapse,
  Avatar,
  Breadcrumbs,
  Button,
  Menu,
  MenuItem,
  //SvgIcon,
  Link as MuiLink,
} from "@material-ui/core";

//import Ki from "../site/members/svg/new_captain_ki_ahp.svg";

const drawerWidth = variables.drawerWidth;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: variables.colorBlue2,
    color: "white",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  listItem: {
    color: "white",
    paddingLeft: "5px",
    paddingRight: "10px",

    "&.red": {
      backgroundColor: "red",
    },
    "&.blue": {
      backgroundColor: "blue",
    },
    "&.green": {
      backgroundColor: "green",
    },
    "&.orange": {
      backgroundColor: "orange",
    },
  },
  nested: {
    paddingLeft: theme.spacing(2) - 1,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    // display: "none",
    // [theme.breakpoints.down('sm')] : {
    //   display: 'flex'
    // }
  },
  drawerMain: {
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
      //width: drawerWidth,
    },
  },
  drawer: {
    width: drawerWidth,
    whiteSpace: "nowrap",
    //flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      //width: theme.spacing(9) + 1,
      width: theme.spacing(6) - 2,
    },
  },
  list: {
    paddingTop: "0px",
  },
  istItemIcon: {
    minWidth: "35px",
  },
  listItemText: {
    whiteSpace: "normal",
    marginLeft: "5px",
    "& span": {
      fontSize: "0.9rem",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: `calc(100% - ${drawerWidth}px)`,
    position: "relative",
    minHeight: "100vh",
    paddingBottom: theme.spacing(10),
    //width : '100%',
    //overflowAnchor : 'none'
  },

  muiLink: {
    color: "white !important",
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const DashboardLayout = ({ children, ...rest }) => {
  //const isCancelled = React.useRef(false);
  //const [sessionChecked, setSessionChecked] = React.useState(false);
  const [sessionChecked, setSessionChecked] = React.useState(true);
  //const auth = useAuth();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { state } = useStore();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const usermanagementSub = [
    {
      id: "studios_studiomanagers",
      title: "Studio Managers",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/",
      openSubMenu: false,
    },
    {
      id: "studios_instructors",
      title: "Instructors",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/",
      openSubMenu: false,
    },
    {
      id: "studios_studentsearch",
      title: "Student Search",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/",
      openSubMenu: false,
    },
  ];

  const setupOpSubs = [
    {
      id: "setup_programs",
      title: "Programs",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-programs",
      openSubMenu: false,
    },
    {
      id: "setup_awards",
      title: "Awards",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-awards",
      openSubMenu: false,
    }
  ];

  const _menus = [
    {
      id: "studios",
      title: "Studios",
      style: "red",
      submenus: [],
      icon: <InboxIcon style={{ color: "white" }} />,
      link: "/dashboard/studios",
      openSubMenu: false,
    },
    {
      id: "appmanagement",
      title: "App Management",
      style: "blue",
      submenus: [],
      icon: <InboxIcon style={{ color: "white" }} />,
      link: "/dashboard/studio/vvvvv2222",
      openSubMenu: false,
    },
    {
      id: "setupoperations",
      title: "Setup Operations",
      style: "green",
      submenus: setupOpSubs,
      icon: <InboxIcon style={{ color: "white" }} />,
      link: "/",
      openSubMenu: false,
    },
    {
      id: "usermanagement",
      title: "User Management",
      style: "orange",
      submenus: usermanagementSub,
      icon: <InboxIcon style={{ color: "white" }} />,
      link: "",
      openSubMenu: false,
    },
  ];

  const handleDrawerToggle = () => {
    const _width = window.innerWidth;
    if (_width <= 600) {
      setMobileOpen(!mobileOpen);
    } else {
      setOpen(!open);
      setMobileOpen(false);
    }
  };

  const [menus, setMenus] = useState(_menus);
  const container = window !== undefined ? () => window.document.body : undefined;

  const handMenuClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    const found = menus.find((el) => el.id === id);
    found.openSubMenu = !found.openSubMenu;
    setMenus([...menus]);
  };

  const drawer = (
    <>
      <div className={classes.toolbar}>
        <img src="/assets/logos/logo.jpg" width="100%" alt="logo" />
      </div>
      <Divider />
      <List className={classes.list}>
        {menus.map((item, index) => (
          <div key={item.id} index={index}>
            <ListItem
              button
              component={item.link !== "" ? Link : null}
              to={item.link}
              className={`${classes.listItem} ${item.style}`}
              onClick={(e) =>
                item.submenus.length > 0 ? handMenuClick(e, item.id) : null
              }
            >
              <ListItemIcon className={classes.istItemIcon}>
                <YourSvg />
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                className={classes.listItemText}
              />
              {item.submenus.length > 0 &&
                (item.openSubMenu ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            {item.submenus.length > 0 && (
              <Collapse in={item.openSubMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.submenus.map((subItem, index) => (
                    <div key={subItem.id} index={index}>
                      <ListItem
                        button
                        className={classes.nested}
                        component={subItem.link !== "" ? Link : null}
                        to={subItem.link}
                      >
                        <ListItemIcon className={classes.istItemIcon}>
                          <YourSvg />
                        </ListItemIcon>
                        <ListItemText primary={subItem.title} />
                      </ListItem>
                    </div>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
    </>
  );

  const breadcrumbs = (
    <Breadcrumbs
      aria-label="breadcrumb"
      style={{ flexGrow: 1, color: "white" }}
    >
      {state.breadcrumbs &&
        state.breadcrumbs.map((breadcrumb, index) => {
          if (index + 1 === state.breadcrumbs.length) {
            return (
              <Typography color="initial" key={index}>
                {" "}
                {breadcrumb.title}{" "}
              </Typography>
            );
          }
          return (
            <MuiLink
              className={classes.muiLink}
              color="initial"
              component={Link}
              to={breadcrumb.link}
              key={index}
            >
              {breadcrumb.title}
            </MuiLink>
          );
        })}
    </Breadcrumbs>
  );

  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };

  // React.useEffect(() => {
  //   const checkAuth = async () => {
  //     if (!isCancelled.current) {
  //       const loggedIn = await auth.loggedIn();
  //       // console.log({ loggedIn })
  //       if (!loggedIn) {
  //         navigate('/member/login');
  //       } else {
  //         setSessionChecked(true);
  //       }
  //     }
  //   };
  //   checkAuth();
  //   return () => {
  //     isCancelled.current = true;
  //   };
  // }, [auth, navigate]);

  if (!sessionChecked) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            {breadcrumbs}
            {/* <Typography noWrap style={{flexGrow : 1}}>
              Dash board
            </Typography> */}

            {/*  */}

            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              //variant="contained"
              //color="primary"
              onClick={handleClick2}
            >
              <Avatar src="/broken-image.jpg" />
              <Typography style={{ color: "white", marginLeft: "8px" }}>
                Adrian Adams
              </Typography>
            </Button>

            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose2}
            >
              <StyledMenuItem>
                <ListItemIcon>
                  <SendIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Sent mail" />
              </StyledMenuItem>
              <StyledMenuItem>
                <ListItemIcon>
                  <DraftsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Drafts" />
              </StyledMenuItem>
              <StyledMenuItem>
                <ListItemIcon>
                  <InboxIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Inbox" />
              </StyledMenuItem>
            </StyledMenu>
          </Toolbar>
        </AppBar>

        <nav className={classes.drawerMain} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            ></Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              open
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    );
  }
};

const DashboardLayoutRoute = ({ component: RenderComponent, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout>
          <RenderComponent {...matchProps} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardLayoutRoute;
